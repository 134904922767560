import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{staticClass:"no-print",staticStyle:{"left":"0","overflow-y":"unset"},attrs:{"app":""}},[_c('div',{staticClass:"topbar-logo-bg",style:({ 'background-image': 'url(' + require('../assets/MK_logo.svg') + ')' })}),_c(VAppBarNavIcon,{staticClass:"mr-3",attrs:{"color":"white","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.showDrawer(true)},"mouseover":function($event){$event.stopPropagation();return _vm.showDrawer(true)}}},[_c(VAvatar,{attrs:{"color":"white","size":"37"}},[_c(VIcon,{attrs:{"color":"black"}},[_vm._v("mdi-menu")])],1)],1),_vm._l((_vm.page.gauges),function(el,idx){return _c('div',{key:idx,staticClass:"status-container"},[(el.click)?[_c(VBtn,{staticClass:"gauge-btn",staticStyle:{"min-width":"unset","padding":"6px 8px","margin":"0"},attrs:{"text":"","color":"transparent"},on:{"click":function($event){$event.preventDefault();return el.click.apply(null, arguments)}}},[_c(VIcon,{attrs:{"color":el.visible ? el.color : 'grey'}},[_vm._v(_vm._s(el.icon))]),(el.text.length > 0)?_c('div',{staticClass:"text hidden-sm-and-down"},[_vm._v(_vm._s(el.text))]):_vm._e()],1)]:(el.items.length >= 0)?[_c(VMenu,{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"text":""}},on),[_c(VIcon,{staticClass:"mr-1"},[_vm._v(_vm._s(el.icon))]),(el.text.length > 0)?_c('div',{staticClass:"text hidden-sm-and-down"},[_vm._v(_vm._s(el.text)+" "+_vm._s(el.count > 0 ? el.count : ''))]):_vm._e()],1)]}}],null,true)},[(el.items.length > 0)?_c(VList,_vm._l((el.items),function(item,index){return _c('div',{key:index},[(item.count != 0)?_c(VListItem,{on:{"click":item.click}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":item.color ? item.color : 'black'}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.text + (item.count > 0 ? ' ' + item.count : '')))])],1)],1):_vm._e()],1)}),0):_vm._e()],1)]:_vm._e()],2)}),_c(VSpacer),(_vm.prevSystem)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","to":_vm.getRoutedSystem(_vm.prevSystem.id)}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-chevron-left")])],1)]}}],null,false,920174568)},[_vm._v(" "+_vm._s(_vm.prevSystem.projectName)+" "+_vm._s(_vm.prevSystem.camNo)+" ")]):_vm._e(),(_vm.pageTitle)?_c('div',[_c(VMenu,{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c(VList,{staticClass:"pa-2 py-1",attrs:{"rounded":"","color":_vm.page.title ? 'rgba(255,152,0,0)' : 'rgba(255,152,0,0)',"max-width":"300"}},[(_vm.pageTitle.pId)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c(VListItemTitle,_vm._g(_vm._b({staticClass:"text-h6 text-md-h8"},'v-list-item-title',attrs,false),Object.assign({}, tooltip, menu)),[_vm._v(_vm._s(_vm.pageTitle.name))])]}}],null,true)},[_c('span',[_vm._v("ProjectId "+_vm._s(_vm.pageTitle.pId))])]):_c(VListItemTitle,{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.pageTitle.name))])],1)]}}],null,false,3178967489)},[_c('div',{staticStyle:{"max-height":"500px"}},[(_vm.systems.length > 0)?_c(VList,_vm._l((_vm.systems),function(item,index){return _c('div',{key:index},[(item.id)?_c(VListItem,{attrs:{"to":("/system/" + (item.id))}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.projectName)+" "+_vm._s(item.camNo))])],1)],1):_vm._e()],1)}),0):_vm._e()],1)])],1):_vm._e(),(_vm.nextSystem)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","to":_vm.getRoutedSystem(_vm.nextSystem.id)}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-chevron-right")])],1)]}}],null,false,1661816837)},[_vm._v(" "+_vm._s(_vm.nextSystem.projectName)+" "+_vm._s(_vm.nextSystem.camNo)+" ")]):_vm._e(),_c(VSpacer),(_vm.page.search)?_c(VToolbar,{staticStyle:{"top":"64px","right":"5px"},attrs:{"absolute":""}},[_c(VTextField,{attrs:{"prepend-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","solo":"","flat":"","clearable":"","autofocus":"","hide-details":""},on:{"click:clear":_vm.clearSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),(_vm.page.criticalCron)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"id":"cron-alert"}},[_vm._v("mdi-alert")])],1)]}}],null,false,1400219513)},[_c('span',[_vm._v(_vm._s(_vm.$t('cron_warning')))])]):_vm._e(),(_vm.input.new.type && _vm.input.new.type !== null)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.page.searchButton)?_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleSearchBar.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-magnify")])],1):_vm._e()]}}],null,false,2959625133)},[_c('span',[_vm._v(_vm._s(_vm.$t('search')))])]):_vm._e(),(_vm.table.sortItems.length > 0)?_c(VMenu,{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [(_vm.input.new.type && _vm.input.new.type !== null)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, onMenu, onTooltip)),[_c(VIcon,[_vm._v("mdi-sort-variant")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('filter_by')))])]):_vm._e()]}}],null,false,3812365167)},[_c(VList,{attrs:{"min-width":"200"}},_vm._l((_vm.table.sortItems),function(item,index){return _c(VListItem,{key:index,on:{"click":function($event){(_vm.table.sorting !== item.sorting.toString() ? _vm.setSorting( item.sorting ) : '')}}},[_c(VListItemAction,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,{style:({fontWeight: (_vm.table.sorting === item.sorting.toString() ? 'bold' : 'normal')})},[_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")])],1)}),1)],1):_vm._e(),(_vm.input.new.type && _vm.input.new.type !== null && _vm.userRole <= 1)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.showNewInputDialog.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-plus-circle-outline")])],1)]}}],null,false,2213621344)},[_c('span',[_vm._v(_vm._s(_vm.$t('toolbar_new'))+" "+_vm._s(_vm.input.new.type.toUpperCase()))])]):_vm._e(),_c(VMenu,{attrs:{"offset-y":"","z-index":"12"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VBadge,_vm._g({attrs:{"overlap":"","content":_vm.page.notificationCount,"value":_vm.page.notificationCount}},on),[_c(VAvatar,{attrs:{"color":"orange red--after","size":"40"}},[_c('span',{staticClass:"white--text headline",staticStyle:{"position":"absolute"}},[_vm._v(" "+_vm._s(_vm.userLetter)+" ")]),_c('img',{attrs:{"src":require('../assets/icons/'+_vm.account.user.user.avatar+'.png')}})])],1)],1)]}}])},[_c(VList,[_c(VListItemGroup,{attrs:{"color":"black"}},_vm._l((_vm.userMenuItems),function(item,i){return _c(VListItem,{key:i,attrs:{"to":item.route}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t(item.name)))])],1)],1)}),1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }