const state = {
    pagination: { multiSort: true },
    sorting: [],
    sortItems: [],
    searchText: '',
}


const actions = {
    setSearchText({ commit }, search) {
        commit('setSearchText', search)
    },

    setSorting({ commit }, sorting) {
        commit('setSorting', sorting)
    },

    setSortOptions({ commit }, options) {
        commit('setSortOptions', options)
    },

    setSortItems({ commit }, items) {
        commit('setSortItems', items)
    },
}


const mutations = {
    setSearchText(tabelState, search) {
        tabelState.searchText = search
    },

    setSorting(tabelState, sorting) {
        if (sorting) {
            const sortBy = sorting.map(x => x[0])
            const sortDesc = sorting.map(x => (x.length > 1 && x[1] ? x[1] : false))
            tabelState.sorting = sorting.toString()
            tabelState.pagination.sortBy = sortBy
            tabelState.pagination.sortDesc = sortDesc
            tabelState.pagination.itemsPerPage = -1
        } else {
            tabelState.sorting = sorting
            tabelState.pagination.sortBy = sorting
            tabelState.pagination.sortDesc = false
            tabelState.pagination.itemsPerPage = -1
        }
    },

    setSortOptions(tabelState, options) {
        tabelState.pagination = options
    },

    setSortItems(tabelState, items) {
        tabelState.sortItems = items
    },
}


export const table = {
    namespaced: true,
    state,
    actions,
    mutations,
}
