<template>
    <v-app-bar app style="left:0;overflow-y:unset" class="no-print">
        <div class="topbar-logo-bg"
            :style="{ 'background-image': 'url(' + require('../assets/MK_logo.svg') + ')' }">
        </div>

        <v-app-bar-nav-icon color="white"
            icon
            @click.stop="showDrawer(true)"
            @mouseover.stop="showDrawer(true)"
            class="mr-3">
            <v-avatar color="white"
                size="37">
                <v-icon color="black">mdi-menu</v-icon>
            </v-avatar>
        </v-app-bar-nav-icon>

        <div class="status-container"
            v-for="(el, idx) in page.gauges"
            :key="idx">

            <template v-if="el.click">
                <v-btn class="gauge-btn"
                    text
                    style="min-width: unset;padding: 6px 8px;margin:0"
                    color="transparent"
                    @click.prevent="el.click">
                    <v-icon :color="el.visible ? el.color : 'grey'">{{ el.icon }}</v-icon>
                    <div class="text hidden-sm-and-down"
                        v-if="el.text.length > 0">{{ el.text }}</div>
                </v-btn>
            </template>

            <template v-else-if="el.items.length >= 0">
                <v-menu bottom offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn text
                            v-on="on">
                            <v-icon class="mr-1">{{ el.icon }}</v-icon>
                            <div class="text hidden-sm-and-down"
                                v-if="el.text.length > 0">{{ el.text }} {{ el.count > 0 ? el.count : ''}}</div>
                        </v-btn>
                    </template>

                    <v-list v-if="el.items.length > 0">
                        <div v-for="(item, index) in el.items"
                                :key="index">
                            <v-list-item v-if="item.count != 0"
                                        @click="item.click">
                                <v-list-item-icon>
                                    <v-icon :color="item.color ? item.color : 'black'">{{ item.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>{{ item.text + (item.count > 0 ? ' ' + item.count : '') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </div>
                    </v-list>
                </v-menu>
            </template>
        </div>

        <v-spacer></v-spacer>

        <v-tooltip v-if="prevSystem" bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon
                    v-bind="attrs"
                    v-on="on"
                    :to="getRoutedSystem(prevSystem.id)">
                <v-icon class="mr-1">mdi-chevron-left</v-icon>
            </v-btn>
            </template>
            {{ prevSystem.projectName }} {{ prevSystem.camNo }}
        </v-tooltip>

        <div v-if="pageTitle">
            <v-menu bottom offset-y>
                <template v-slot:activator="{ on: menu }">
                    <v-list
                        rounded
                        :color="page.title ? 'rgba(255,152,0,0)' : 'rgba(255,152,0,0)'"
                        max-width="300"
                        class="pa-2 py-1">
                        <v-tooltip v-if="pageTitle.pId" bottom>
                            <template v-slot:activator="{ on: tooltip, attrs }">
                                <v-list-item-title
                                v-bind="attrs"
                                v-on="{ ...tooltip, ...menu }"
                                class="text-h6 text-md-h8">{{ pageTitle.name }}</v-list-item-title>
                            </template>
                            <span>ProjectId {{ pageTitle.pId }}</span>
                        </v-tooltip>
                        <v-list-item-title v-else class="text-h6">{{ pageTitle.name }}</v-list-item-title>
                    </v-list>
                </template>

                <div style="max-height:500px;">
                    <v-list v-if="systems.length > 0">
                        <div v-for="(item, index) in systems"
                                :key="index">
                            <v-list-item v-if="item.id"
                                :to="`/system/${item.id}`">

                                <v-list-item-content>
                                    <v-list-item-title>{{ item.projectName }} {{ item.camNo }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </div>
                    </v-list>
                </div>
            </v-menu>
        </div>

        <v-tooltip v-if="nextSystem" bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon
                    v-bind="attrs"
                    v-on="on"
                    :to="getRoutedSystem(nextSystem.id)">
                    <v-icon class="mr-1">mdi-chevron-right</v-icon>
                </v-btn>
            </template>
            {{ nextSystem.projectName }} {{ nextSystem.camNo }}
        </v-tooltip>

        <v-spacer></v-spacer>

        <v-toolbar absolute
            style="top:64px;right:5px;"
            v-if="page.search">
            <v-text-field v-model="search"
                prepend-icon="mdi-magnify"
                @click:clear="clearSearch"
                :label="$t('search')"
                single-line
                solo
                flat
                clearable
                autofocus
                hide-details></v-text-field>
        </v-toolbar>

        <v-tooltip v-if="page.criticalCron"
            bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon
                    v-bind="attrs"
                    v-on="on">
                    <v-icon id="cron-alert">mdi-alert</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('cron_warning') }}</span>
        </v-tooltip>

        <v-tooltip v-if="input.new.type && input.new.type !== null"
            bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon
                    v-bind="attrs"
                    v-on="on"
                    v-if="page.searchButton"
                    @click.stop="toggleSearchBar">
                    <v-icon>mdi-magnify</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('search') }}</span>
        </v-tooltip>

        <v-menu
            v-if="table.sortItems.length > 0"
            bottom
            offset-y>
            <template v-slot:activator="{ on: onMenu }">
                <v-tooltip v-if="input.new.type && input.new.type !== null"
                    bottom>
                    <template v-slot:activator="{ on: onTooltip, attrs }">
                        <v-btn icon
                                v-bind="attrs"
                                v-on="{ ...onMenu, ...onTooltip }">
                            <v-icon>mdi-sort-variant</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('filter_by') }}</span>
                </v-tooltip>
            </template>

            <v-list min-width="200">
                <v-list-item v-for="(item, index) in table.sortItems"
                    :key="index"
                    @click="(table.sorting !== item.sorting.toString() ? setSorting( item.sorting ) : '')">
                    <v-list-item-action>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content
                        :style="{fontWeight: (table.sorting === item.sorting.toString() ? 'bold' : 'normal')}">
                        {{ $t(item.name) }}
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>

        <v-tooltip v-if="input.new.type && input.new.type !== null && userRole <= 1"
            bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click.stop="showNewInputDialog"
                >
                    <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('toolbar_new') }} {{ input.new.type.toUpperCase() }}</span>
        </v-tooltip>

        <v-menu offset-y z-index="12">
            <template v-slot:activator="{ on }">
                <v-btn icon
                    v-on="on">
                    <v-badge overlap
                        v-on="on"
                        :content="page.notificationCount"
                        :value="page.notificationCount">

                        <v-avatar color="orange red--after"
                            size="40">
                            <span class="white--text headline"
                                style="position:absolute;">
                                {{ userLetter }}
                            </span>
                            <img
                                :src="require('../assets/icons/'+account.user.user.avatar+'.png')"
                            >
                        </v-avatar>
                    </v-badge>
                </v-btn>
            </template>

            <v-list>
                <v-list-item-group color="black">
                    <v-list-item
                        v-for="(item, i) in userMenuItems"
                        :key="i"
                        :to="item.route">
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>

    </v-app-bar>
</template>


<script>
import { mapState, mapActions } from 'vuex'
import { rest } from '../_helpers'

export default {
    props: {
        title: String,
    },

    data() {
        return {
            userLetter: '',
            userMenuItems: [
                {
                    name: 'settings.name',
                    icon: 'mdi-cog',
                    route: '/settings',
                },
                {
                    name: 'logout',
                    icon: 'mdi-logout',
                    route: '/login',
                },
            ],
            userRole: localStorage.getItem(process.env.VUE_APP_STORAGE_KEY) ? JSON.parse(localStorage.getItem(process.env.VUE_APP_STORAGE_KEY)).user.role : null,
            systems: [],
            nextSystem: null,
            prevSystem: null,
        }
    },

    computed: {
        ...mapState({
            page: 'page',
            input: 'input',
            table: 'table',
            account: 'account',
        }),
        search: {
            get() {
                return this.table.searchText
            },
            set(val) {
                this.setSearch(val)
            },
        },
        pageTitle() {
            return this.page.title
        },
    },

    methods: {
        ...mapActions({
            showDrawer: 'page/showDrawer',
            showInputDialog: 'input/showDialog',
            toggleSearchBar: 'page/toggleSearchBar',
            setSearch: 'table/setSearchText',
            setSorting: 'table/setSorting',
            setInputDialog: 'input/setInputDialog',
        }),

        setSkipStatus(currentId) {
            if (currentId >= 0) {
                this.nextSystem = (currentId < (this.systems.length - 1)) ? this.systems[currentId + 1] : null
                this.prevSystem = (currentId > 0) ? this.systems[currentId - 1] : null
            } else {
                this.nextSystem = null
                this.prevSystem = null
            }
        },

        requestNotification() {
            Notification.requestPermission()
        },

        clearSearch() {
            this.setSearch('')
            this.toggleSearchBar()
        },

        showNewInputDialog() {
            this.setInputDialog()
            this.showInputDialog(true)
        },

        getRoutedSystem(systemId) {
            return this.$route.path.replace(/\/\d+/, `/${systemId}`)
        },
    },

    created() {
        this.userLetter = this.account.user.user.displayname[0].toUpperCase()

        rest.getOrderedSystems()
            .then(response => {
                this.systems = response
            })
    },

    watch: {
        pageTitle(val) {
            const currentId = this.systems.findIndex(system => system.id === this.page.title.id)

            this.setSkipStatus(currentId)
        },
    },
}
</script>


<style lang="stylus">
    .status-container
        display contents

    .v-toolbar__title
        margin-right .8em

    .topbar-logo-bg
        width 60em
        position absolute
        height 64px
        background-size 100%
        background-position -26em -10em
        left 0
        z-index -1

    @media (max-width: 959px)
        .gauge-btn
            min-width auto

        .topbar-logo-bg
            height 56px
            background-position-x -26.6em
</style>

<style lang="stylus" scoped>
    .v-list__tile__action
        min-width auto

    #cron-alert
        color #fb8c00
        animation: blink 1.3s infinite;

    @keyframes blink
        0%
            color #fb8c00
        50%
            color rgba(0, 0, 0, 0.54)
        100%
            color #fb8c00
</style>
